"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toHsv = toHsv;
exports.fromHsv = fromHsv;
exports.createPanResponder = createPanResponder;
exports.rotatePoint = rotatePoint;

var _tinycolor = _interopRequireDefault(require("tinycolor2"));

var _reactNative = require("react-native");

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

/**
 * Converts color to hsv representation.
 * @param {string} color any color represenation - name, hexa, rgb
 * @return {object} { h: number, s: number, v: number } object literal
 */
function toHsv(color) {
  return (0, _tinycolor["default"])(color).toHsv();
}
/**
 * Converts hsv object to hexa color string.
 * @param {object} hsv { h: number, s: number, v: number } object literal
 * @return {string} color in hexa representation
 */


function fromHsv(hsv) {
  return (0, _tinycolor["default"])(hsv).toHexString();
}

var fn = function fn() {
  return true;
};
/**
 * Simplified pan responder wrapper.
 */


function createPanResponder(_ref) {
  var _ref$onStart = _ref.onStart,
      onStart = _ref$onStart === void 0 ? fn : _ref$onStart,
      _ref$onMove = _ref.onMove,
      onMove = _ref$onMove === void 0 ? fn : _ref$onMove,
      _ref$onEnd = _ref.onEnd,
      onEnd = _ref$onEnd === void 0 ? fn : _ref$onEnd;
  return _reactNative.PanResponder.create({
    onStartShouldSetPanResponder: fn,
    onStartShouldSetPanResponderCapture: fn,
    onMoveShouldSetPanResponder: fn,
    onMoveShouldSetPanResponderCapture: fn,
    onPanResponderTerminationRequest: fn,
    onPanResponderGrant: function onPanResponderGrant(evt, state) {
      return onStart({
        x: evt.nativeEvent.pageX,
        y: evt.nativeEvent.pageY
      }, evt, state);
    },
    onPanResponderMove: function onPanResponderMove(evt, state) {
      return onMove({
        x: evt.nativeEvent.pageX,
        y: evt.nativeEvent.pageY
      }, evt, state);
    },
    onPanResponderRelease: function onPanResponderRelease(evt, state) {
      return onEnd({
        x: evt.nativeEvent.pageX,
        y: evt.nativeEvent.pageY
      }, evt, state);
    }
  });
}
/**
 * Rotates point around given center in 2d.
 * Point is object literal { x: number, y: number }
 * @param {point} point to be rotated
 * @param {number} angle in radians
 * @param {point} center to be rotated around
 * @return {point} rotated point
 */


function rotatePoint(point, angle) {
  var center = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
    x: 0,
    y: 0
  };
  // translation to origin
  var transOriginX = point.x - center.x;
  var transOriginY = point.y - center.y; // rotation around origin

  var rotatedX = transOriginX * Math.cos(angle) - transOriginY * Math.sin(angle);
  var rotatedY = transOriginY * Math.cos(angle) + transOriginX * Math.sin(angle); // translate back from origin

  var normalizedX = rotatedX + center.x;
  var normalizedY = rotatedY + center.y;
  return {
    x: normalizedX,
    y: normalizedY
  };
}
"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
    result["default"] = mod;
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var theme = {
    OBJECT_PREVIEW_ARRAY_MAX_PROPERTIES: 10,
    OBJECT_PREVIEW_OBJECT_MAX_PROPERTIES: 5,
    OBJECT_NAME_COLOR: 'rgb(136, 19, 145)',
    OBJECT_VALUE_NULL_COLOR: 'rgb(128, 128, 128)',
    OBJECT_VALUE_UNDEFINED_COLOR: 'rgb(128, 128, 128)',
    OBJECT_VALUE_REGEXP_COLOR: 'rgb(196, 26, 22)',
    OBJECT_VALUE_STRING_COLOR: 'rgb(196, 26, 22)',
    OBJECT_VALUE_SYMBOL_COLOR: 'rgb(196, 26, 22)',
    OBJECT_VALUE_NUMBER_COLOR: 'rgb(28, 0, 207)',
    OBJECT_VALUE_BOOLEAN_COLOR: 'rgb(28, 0, 207)',
    OBJECT_VALUE_FUNCTION_PREFIX_COLOR: 'rgb(13, 34, 170)',
    ARROW_COLOR: '#6e6e6e',
    ARROW_MARGIN_RIGHT: 3,
    ARROW_FONT_SIZE: 12,
    ARROW_ANIMATION_DURATION: '0',
};
var Inspect = /** @class */ (function (_super) {
    __extends(Inspect, _super);
    function Inspect() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = { expanded: false };
        return _this;
    }
    Inspect.prototype.render = function () {
        var _this = this;
        var _a = this.props, name = _a.name, value = _a.value;
        var expanded = this.state.expanded;
        var toggle = (react_1.default.createElement(react_native_1.View, { style: { width: 40, height: 40 } }, name &&
            ((Array.isArray(value) && value.length) ||
                (value &&
                    typeof value === 'object' &&
                    !Array.isArray(value) &&
                    Object.keys(value).length)) ? (react_1.default.createElement(react_native_1.Button, { onPress: function () { return _this.setState(function (s) { return ({ expanded: !s.expanded }); }); }, title: !expanded ? '▶' : '▼' })) : null));
        var nameComponent = name ? (react_1.default.createElement(react_native_1.Text, { style: { color: theme.OBJECT_NAME_COLOR } }, name)) : null;
        if (Array.isArray(value)) {
            if (name) {
                return (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(react_native_1.View, { style: { flexDirection: 'row', alignItems: 'center' } },
                        toggle,
                        nameComponent,
                        react_1.default.createElement(react_native_1.Text, null, ": " + (value.length === 0 ? '[]' : expanded ? '[' : '[...]'))),
                    expanded ? (react_1.default.createElement(react_native_1.View, { style: { marginLeft: 40 } },
                        value.map(function (v, i) { return (react_1.default.createElement(react_native_1.View, { key: i, style: { marginLeft: 40 } },
                            react_1.default.createElement(Inspect, { value: v }))); }),
                        react_1.default.createElement(react_native_1.View, { style: { marginLeft: 20 } },
                            react_1.default.createElement(react_native_1.Text, null, "]")))) : null));
            }
            return (react_1.default.createElement(react_native_1.View, null,
                react_1.default.createElement(react_native_1.Text, null, "["),
                value.map(function (v, i) { return (react_1.default.createElement(react_native_1.View, { key: i, style: { marginLeft: 20 } },
                    react_1.default.createElement(Inspect, { value: v }))); }),
                react_1.default.createElement(react_native_1.Text, null, "]")));
        }
        if (value && typeof value === 'object' && !(value instanceof RegExp)) {
            if (name) {
                return (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(react_native_1.View, { style: { flexDirection: 'row', alignItems: 'center' } },
                        toggle,
                        nameComponent,
                        react_1.default.createElement(react_native_1.Text, null, ": " + (Object.keys(value).length === 0 ? '{}' : expanded ? '{' : '{...}'))),
                    expanded ? (react_1.default.createElement(react_native_1.View, { style: { marginLeft: 40 } },
                        Object.entries(value).map(function (_a) {
                            var key = _a[0], v = _a[1];
                            return (react_1.default.createElement(react_native_1.View, { key: key },
                                react_1.default.createElement(Inspect, { name: key, value: v })));
                        }),
                        react_1.default.createElement(react_native_1.View, { style: { marginLeft: 20 } },
                            react_1.default.createElement(react_native_1.Text, null, '}')))) : null));
            }
            return (react_1.default.createElement(react_native_1.View, null,
                react_1.default.createElement(react_native_1.Text, null, '{'),
                Object.entries(value).map(function (_a) {
                    var key = _a[0], v = _a[1];
                    return (react_1.default.createElement(react_native_1.View, { key: key },
                        react_1.default.createElement(Inspect, { name: key, value: v })));
                }),
                react_1.default.createElement(react_native_1.Text, null, '}')));
        }
        if (name) {
            return (react_1.default.createElement(react_native_1.View, { style: { flexDirection: 'row', alignItems: 'center' } },
                toggle,
                nameComponent,
                react_1.default.createElement(react_native_1.Text, null, ": "),
                react_1.default.createElement(Value, { value: value })));
        }
        return (react_1.default.createElement(react_native_1.View, null,
            react_1.default.createElement(Value, { value: value })));
    };
    return Inspect;
}(react_1.Component));
function Value(_a) {
    var value = _a.value;
    if (value === null) {
        return react_1.default.createElement(react_native_1.Text, { style: { color: theme.OBJECT_VALUE_NULL_COLOR } }, "null");
    }
    if (value === undefined) {
        return react_1.default.createElement(react_native_1.Text, { style: { color: theme.OBJECT_VALUE_UNDEFINED_COLOR } }, "undefined");
    }
    if (value instanceof RegExp) {
        return (react_1.default.createElement(react_native_1.Text, { style: { color: theme.OBJECT_VALUE_REGEXP_COLOR } }, "/" + value.source + "/" + value.flags));
    }
    switch (typeof value) {
        case 'string':
            return (react_1.default.createElement(react_native_1.Text, { style: { color: theme.OBJECT_VALUE_STRING_COLOR } }, JSON.stringify(value)));
        case 'number':
            return (react_1.default.createElement(react_native_1.Text, { style: { color: theme.OBJECT_VALUE_NUMBER_COLOR } }, JSON.stringify(value)));
        case 'boolean':
            return (react_1.default.createElement(react_native_1.Text, { style: { color: theme.OBJECT_VALUE_BOOLEAN_COLOR } }, JSON.stringify(value)));
        case 'function':
            return react_1.default.createElement(react_native_1.Text, { style: { color: theme.OBJECT_VALUE_FUNCTION_PREFIX_COLOR } }, "[Function]");
        default:
            return react_1.default.createElement(react_native_1.Text, null, JSON.stringify(value));
    }
}
exports.default = Inspect;
